<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Company | Octoa"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
          <div
            v-show="loading"
            class="w-full px-2 text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="50"
              class="mt-32 text-center"
            >
          </div>
          <div
            v-show="!loading"
            class="w-full"
          >
            <h2 class="font-bold mb-5">
              Brand logo
            </h2>
            <div class="flex flex-wrap items-center">
              <div
                class="w-full md:w-auto mb-5"
              >
                <div
                  v-if="logo.path"
                  class="inline-block"
                  :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                >
                  <img
                    v-show="!uploadLoading"
                    :src="logo.path"
                    width="100%"
                    :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                  >
                  <img
                    v-show="uploadLoading"
                    src="@/assets/img/icons/loader.svg"
                    width="40"
                    style="transform:translate(-50%,-50%); top:50%; left:50%; "
                  >
                </div>
              </div>
              <div class="w-full md:w-1/3  mb-5">
                <div
                  v-if="!logo.path"
                  class="inline-block mt-5"
                >
                  <input
                    ref="file"
                    type="file"
                    style="display:none;"
                    @change="fileUpload"
                  >
                  <a
                    class="green-btn"
                    @click="$refs.file.click()"
                  >Upload logo</a>
                  <v-help-tooltip
                    class="px-1 -mt-px"
                    :options="tooltipContent"
                  />
                </div>
                <div v-else>
                  <a
                    class="green-btn md:mx-4"
                    @click="removeLogo"
                  >Remove logo</a>
                </div>
              </div>
            </div>
            <div
              v-if="uploadError"
              class="block mt-2 text-red"
            >
              Upload an image within 5mb, PNG or JPG format and a minimum resolution of 500px width or 500px height.
            </div>

            <hr class="mb-8 mt-4">

            <h2 class="font-bold mb-5">
              Company details
            </h2>

            <div class="input-group">
              <label>Company name</label>
              <input
                id="companyName"
                v-model="companyName"
                type="text"
                class="w-full"
                name="companyName"
              >
            </div>

            <div class="input-group">
              <label>Company address</label>
              <input
                id="companyAddress"
                v-model="companyAddress"
                type="text"
                class="w-full"
                name="companyAddress"
              >
            </div>
            <div class="input-group">
              <label>Postal code</label>
              <input
                id="postalCode"
                v-model="companyPostalCode"
                type="text"
                class="w-full"
                name="companyPostalCode"
              >
            </div>
            <div class="input-group">
              <label>City</label>
              <input
                id="city"
                v-model="companyCity"
                type="text"
                class="w-full"
                name="companyCity"
              >
            </div>

            <div class="input-group">
              <label for="country"> Country </label>
              <div class="select-container w-full">
                <select
                  v-model="companyCountry"
                  v-validate="'required'"
                  name="country"
                >
                  <option value="">
                    Select a country
                  </option>
                  <option
                    v-for="(item, index) in countriesList"
                    :key="index"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="input-group">
              <label>Email address</label>
              <input
                id="email"
                v-model="companyEmail"
                type="text"
                class="w-full"
                name="companyEmail"
              >
            </div>

            <div class="input-group">
              <label>Phone number</label>
              <input
                id="phone"
                v-model="companyPhone"
                type="text"
                class="w-full"
                name="companyPhone"
              >
            </div>

            <div class="input-group">
              <label for="timezone"> Timezone </label>
              <div class="select-container w-full">
                <select
                  v-model="companyTimezone"
                  v-validate="'required'"
                  name="timezone"
                >
                  <option value="">
                    Select a timezone
                  </option>
                  <option
                    v-for="(item, key) in timezoneList"
                    :key="key"
                    :value="key"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        class="w-full px-4 mt-8 lg:px-0 lg:mt-4 mb-20 text-right"
      >
        <v-button @click="save">
          Save
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { format } from 'date-fns'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'
import { listTimeZones } from 'timezone-support'

let cancelSource

  export default {
    name: 'Settings',
    data(){
      return {
        loading: true,
        isSaving: false,
        logo: {path:'', width:0, height:0},
        uploadError: false,
        user: auth.user(),
        brandButtonBackground: { hex: '#000000'},
        brandButtonTextColor: { hex: '#FFFFFF'},
        companyName: '',
        companyAddress: '',
        companyPostalCode: '',
        companyCity: '',
        companyCountry: '',
        companyEmail: '',
        companyPhone: '',
        companyTimezone: '',
        user: auth.user(),
        displayBgPicker: false,
        displayTextPicker: false,
        countriesList: [],
        timezoneList: [],
        uploadLoading: false,
        languageContent: {
          content: 'Choose a language to display on the client view for your proposals and invoices.'
        },
        tooltipContent: {
          content: `
          Upload your logo preferably with a minimum resolution of 500px width or 500px height with extension JPG or PNG.
          `
        },
      }
    },
    async mounted(){
      await this.getCountriesList()
      await this.getTimeZoneList()
      await this.refresh()
    },
    methods: {
      async removeLogo(){
        try {
          const { data } = await this.$api.get('company').removeLogo(this.user.company.id)
          this.logo = {}
          await this.refresh()
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      },
      async getCountriesList() {
        try {
          const { data } = await this.$api.get('country').all()
          this.countriesList = data.countries
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      },
      async getTimeZoneList() {
        try {
          const { data } = await this.$api.get('timezone').all()
          this.timezoneList = data.timezone
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      },
      showBgPicker() {
        document.addEventListener('click', this.documentBgClick)
        this.displayBgPicker = true
      },
      showTextPicker() {
        document.addEventListener('click', this.documentTextClick)
        this.displayTextPicker = true
      },
      hideBgPicker() {
        document.removeEventListener('click', this.documentBgClick)

        this.displayBgPicker = false
      },
      hideTextPicker() {
        document.removeEventListener('click', this.documentTextClick)

        this.displayTextPicker = false
      },
      documentBgClick(e) {
        let el = this.$refs.bgColorPicker,
          target = e.target
        if(el !== target && !el.contains(target)) {
          this.hideBgPicker()
        }
      },
      documentTextClick(e) {
        let el = this.$refs.textColorPicker,
          target = e.target
        if(el !== target && !el.contains(target)) {
          this.hideTextPicker()
        }
      },
      async fileUpload(){

        let that = this
        let _URL = window.URL || window.webkitURL
        let file = this.$refs.file.files[0]
        this.uploadError = false
        this.uploadLoading = true

        if (file.size > 5000 * 1024 || (file.type != 'image/jpeg' && file.type != 'image/jpg' && file.type != 'image/png')) {
          this.uploadError = true
          this.uploadLoading = false
          return
        }

        let img = new Image()
        img.onload = function () {
          let hasError = false
          if(this.width > this.height){
            if(this.width < 500){
              hasError = true
            }
          }
          if(this.width < this.height){
            if(this.height < 500){
              hasError = true
            }
          }
          if(this.width == this.height){
            if(this.width < 500 && this.height < 500){
              hasError = true
            }
          }
          if(hasError){
            that.uploadError = true
            that.uploadLoading = false
            return false
          }
        }

        img.src = _URL.createObjectURL(file)
        let formData = new FormData()
        formData.append('image', file)

        try {
          await this.$store.dispatch('company/uploadLogo', formData)
        } catch(e) {
          //this.$toasted.global.api_error(e)
        }

        this.uploadLoading = false
        await this.refresh()

      },
      save(){
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
          }
        })
      },
      async refresh() {
        try {
          await this.$store.dispatch('company/refreshCompanyDetails')

          const company = this.$store.getters['company/details']

          this.logo.path = company.logo
          let that = this
          let image = new Image()
          image.onload = function () {
            that.logo.width = this.width
            that.logo.height = this.height
          }
          image.src = this.logo.path

          this.brandButtonBackground.hex = company.brandButtonBackground
          this.brandButtonTextColor.hex = company.brandButtonTextColor
          this.companyName = company.name
          this.companyAddress = company.address
          this.companyPostalCode = company.postal_code
          this.companyCity = company.city
          this.companyCountry = company.country
          this.companyEmail = company.email
          this.companyPhone = company.phone
          this.companyTimezone = company.timezone

          auth.refreshUser()
          this.loading = false

        } catch(e) {
          this.$toasted.global.api_error(e)
        }
      },
      async doSave() {
        this.saving = true

        const postData = {
          brandButtonBackground: this.brandButtonBackground.hex,
          brandButtonTextColor: this.brandButtonTextColor.hex,
          companyName: this.companyName,
          companyAddress: this.companyAddress,
          companyPostalCode: this.companyPostalCode,
          companyCity: this.companyCity,
          companyCountry: this.companyCountry,
          companyEmail: this.companyEmail,
          companyPhone: this.companyPhone,
          companyTimezone: this.companyTimezone,
        }

        try {
          await this.$store.dispatch('company/patch', postData)
          this.$toasted.global.general_success({
            message : 'Company info updated.'
          })
        } catch(e) {
          this.$toasted.global.api_error(e)
        }

        this.saving = false
        await this.refresh()
      },
    },
  }
</script>
